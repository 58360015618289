import classes from "./GooglePlayButton.module.css"
import {MobixDownloadLinks} from "../../../constants/MobixDownloadLinks";
import React from "react";

const GooglePlayButton = () => (
    <a href={MobixDownloadLinks.ANDROID} className={classes["link-buttons"]}>
        <img src={MobixDownloadLinks.ANDROID_ICON}
             alt="Get it on Google Play"
             className={classes["link-images-google"]}
        />
    </a>
)

export default GooglePlayButton