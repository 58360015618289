export class PhoneModelConstants{
    static phones = [
        'oneplus',
        'huawei',
        'xiaomi',
        'meizu',
        'asus',
        'wiko',
        'lenovo',
        'oppo',
        'vivo',
        'realme',
        'blackview',
        'sony',
        'unihertz',
        'google',
        'hmd-global',
        'htc',
        'samsung'
    ]
}