import {DynamicLinkTypeConstants} from "./DynamicLinkTypeConstants";

export class DynamicLinkContentConstants {

    static ReferTypeContent = {
        linkType: DynamicLinkTypeConstants.REFER,
        mainTitle: 'You\'re invited to join MOBIX!',
        mainContent: 'Now scan the QR code to join MOBIX and start collecting MOBIX Miles!'
    };

    static RequestTypeMobx = {
        linkType: DynamicLinkTypeConstants.REQUEST,
        mainTitle: 'Send MOBX',
        mainContent: 'Now scan the QR code to send MOBX!'
    };

    static RequestTypeFet = {
        linkType: DynamicLinkTypeConstants.REQUEST,
        mainTitle: 'Send FET',
        mainContent: 'Now scan the QR code to send FET!'
    }

    static APN = 'com.datarella.mobix'
    static ISI = '1569145214'
    static IBI = 'com.datarell.Mobix'
}