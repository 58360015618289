import React, {Fragment, useEffect, useState} from "react";
import {HttpConstants} from "../../constants/HttpConstants";
import {Markup} from "interweave";
import './DontKillMyApp.css'
import {MobixDownloadLinks} from "../../constants/MobixDownloadLinks";
import logo from "../../images/logo.svg";
import {useParams} from "react-router-dom";
import {PhoneModelConstants} from "../../constants/PhoneModelConstants";
import {Box, CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const inputLabelStyle = {
    color: 'white',
    "&.Mui-focused": {
        color: "orange"
    }
}

const selectStyle = {
    height: '2.5rem',
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white'
    },
    '& .MuiSvgIcon-root': {
        color: 'white'
    }
}

const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);


const DontKillMyApp = () => {
    const params = useParams()
    const [phoneData, setPhoneData] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(null)
    const [selectedPhoneModel, setSelectedPhoneModel] = useState('general')

    useEffect(() => {
        const fetchPhoneData = async () => {
            const phoneModelQueryValue = selectedPhoneModel !== 'general' ? selectedPhoneModel
                : params.phoneModel.toLowerCase()

            const phoneModel = PhoneModelConstants.phones.includes(phoneModelQueryValue)
                ? phoneModelQueryValue : 'general'

            const response = await fetch(`${HttpConstants.DontKillMyAppUrl}/${phoneModel}.json`)
            const responseData = await response.json()

            setPhoneData(responseData['user_solution'])
            setSelectedPhoneModel(phoneModel)
            setIsLoading(false)
        }

        fetchPhoneData().catch(() => {
            setIsLoading(false)
            setIsError("Error fetching data")
        })

    }, [selectedPhoneModel])

    const handleSelectChange = (event) =>
        setSelectedPhoneModel(event.target.value)


    return (
        <Fragment>
            <div className="content-wrapper">
                <a href={MobixDownloadLinks.MOBIX_WEBSITE}><img src={logo} alt="mobx logo" className={"image"}/></a>
                <FormControl fullWidth sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="select-phone-model" sx={inputLabelStyle}>Phone model</InputLabel>
                    <Select
                        labelId="select-phone-model"
                        id="select-phone-model"
                        value={selectedPhoneModel}
                        label="Phone Model"
                        sx={selectStyle}
                        onChange={handleSelectChange}
                        className="select-options-navbar-main-color">
                        {
                            PhoneModelConstants.phones
                                .map(phone =>
                                    <MenuItem key={phone} value={phone}>{capitalizeFirstLetter(phone)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                {isLoading && <Box className={"box"}>
                    <CircularProgress className={"circular-progress"}/>
                </Box>}
                {isError && <h1 className="error">{isError}</h1>}
                {!isLoading && !isError && <Markup content={phoneData}></Markup>}
            </div>
        </Fragment>
    )
}

export default DontKillMyApp