import React from "react";
import classes from "./MobixCart.module.css"
import MobixContent from "../mobix-content/MobixContent";
import QrCodeModal from "../qr-code-modal/QrCodeModal";

const MobixCart = (props) => {
    return (
        <section className={classes.card}>
            <MobixContent mobixContent={props.content}/>
            <QrCodeModal parsedUrl={props.parsedUrl}/>
        </section>
    )
}

export default MobixCart



