import classes from "./AppleStoreButton.module.css"
import {MobixDownloadLinks} from "../../../constants/MobixDownloadLinks";
import React from "react";

const AppleStoreButton = () => (
    <a href={MobixDownloadLinks.APPLE} className={classes["link-buttons"]}>
        <img src={MobixDownloadLinks.APPLE_ICON}
             alt="Download on the App Store"
             className={classes["link-images"]}
        />
    </a>
)

export default AppleStoreButton