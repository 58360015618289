import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MobixDynamicLinks from './MobixDynamicLinks';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DontKillMyApp from "./components/don\'t-kill-my-app/DontKillMyApp";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path={"/"} element={<MobixDynamicLinks/>}/>
                <Route path={"/don't-kill-my-app/:phoneModel"} element={<DontKillMyApp/>}/>
            </Routes>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
