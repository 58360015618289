import React, {useEffect, useState} from "react";
import {useSearchParams} from 'react-router-dom'
import './MobixDynamicLinks.css';
import MobixCart from "./components/mobix-cart/MobixCart";
import logo from "./images/logo.svg";
import {DynamicLinkContentConstants} from "./constants/DynamicLinkContentConstants";
import {MobixDownloadLinks} from "./constants/MobixDownloadLinks";


const isEmpty = (obj) => obj
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype


const parseSendTxInfo = (params) => {
    const txParameters = Array.from(params)
        .find(it => it[0].includes('denom') === true)
    return txParameters ?
        txParameters[0].split('?').reduce((acc, curr) => {
            const [key, value] = curr.split(':')
            return {[key]: value, ...acc}
        }, {}) : []
}

const parseUrl = (friendAddress, sendTxInfo) => {
    const datarellaDynamicLink = 'https://datarella.page.link/?link='

    if (friendAddress) {
        return `${datarellaDynamicLink}https://refer.mobix.ai/?friendAddress=${friendAddress}&apn=${DynamicLinkContentConstants.APN}&isi=${DynamicLinkContentConstants.ISI}&ibi=${DynamicLinkContentConstants.IBI}`
    } else {
        let uriBip0021String = `${sendTxInfo.denom}:${sendTxInfo.address}`
        if (Object.keys(sendTxInfo).length > 2) uriBip0021String += '?'
        if (sendTxInfo.amount) {
            uriBip0021String += `amount=${sendTxInfo.amount}`
        }
        return uriBip0021String
    }
}
function MobixDynamicLinks() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [mobixContentType, setMobixContentType] = useState({})

    const friendAddress = searchParams.get('friendAddress')
    const sendTxInfo = friendAddress ? undefined : parseSendTxInfo(searchParams.entries());
    const parsedUrl = parseUrl(friendAddress, sendTxInfo)


    useEffect(() => {
        let dynamicLinkRequestContent;
        if (friendAddress) {
            dynamicLinkRequestContent = DynamicLinkContentConstants.ReferTypeContent
        } else {
            if (sendTxInfo.denom === 'afet') {
                dynamicLinkRequestContent = DynamicLinkContentConstants.RequestTypeFet
            } else {
                dynamicLinkRequestContent = DynamicLinkContentConstants.RequestTypeMobx
            }
        }
        setMobixContentType(dynamicLinkRequestContent)
    }, [])


    return (
        <div className={"App"}>
            <div className={"container"}>
                <a href={MobixDownloadLinks.MOBIX_WEBSITE}><img src={logo} alt="mobx logo" className={"image"}/></a>
                <div className={"child"}>
                    {!isEmpty(mobixContentType) && <MobixCart parsedUrl={parsedUrl} content={mobixContentType}/>}
                </div>
                {isEmpty(mobixContentType) && <a className={"main-header"}
                                                 href={MobixDownloadLinks.MOBIX_WEBSITE}>
                    <h1 className={"main-header"}>Welcome to MOBX</h1>
                </a>}
            </div>
        </div>
    );
}

export default MobixDynamicLinks;
