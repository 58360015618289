import React from "react";
import classes from "./MobixContent.module.css";
import AppleStoreButton from "../buttons/app-store-button/AppleStoreButton";
import GooglePlayButton from "../buttons/google-play-button/GooglePlayButton";

const MobixContent = (props) =>
    <div className={classes["mobix-content-container"]}>

        <h1 className={classes["main-text"]}>{props.mobixContent.mainTitle}</h1>

        <div className={classes["list-container"]}>
            <span className={classes["list-number"]}>1</span>
            <p className={classes["list-text"]}>Install the MOBIX wallet on your smartphone...</p>
        </div>

        <div className={classes["list-container"]}>
            <AppleStoreButton/>
            <GooglePlayButton/>
        </div>

        <div className={classes["list-container"]}>
            <span className={classes["list-number"]}>2</span>
            <p className={classes["list-text"]}>{props.mobixContent.mainContent}</p>
        </div>

    </div>


export default MobixContent